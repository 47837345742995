

import { Agent, Plugin } from 'common/types/gateway';
import * as _ from 'lodash';
import { createStore, Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

export interface AppState {
  agents: Agent[];
  plugins: Plugin[];
  connected: boolean;
}

const INITIAL_STATE: AppState = {
  agents: [],
  plugins: [],
  connected: false
};

export enum ActionTypes {
  AgentChange,
  AgentAdded,
  AgentDeleted,
  AgentList,
  SocketConnected,
  SocketDisconnected,
  PluginList
}
interface AgentList { type: ActionTypes.AgentList, agents: Agent[] }
interface AgentChange { type: ActionTypes.AgentChange, changed: Agent }
interface AgentAdded { type: ActionTypes.AgentAdded, added: Agent }
interface AgentDeleted { type: ActionTypes.AgentDeleted, deleted: Agent }
interface SocketConnected { type: ActionTypes.SocketConnected }
interface SocketDisconnected { type: ActionTypes.SocketDisconnected }
interface PluginList { type: ActionTypes.PluginList, plugins: Plugin[] }
export type Actions =
  AgentChange |
  AgentList |
  AgentAdded |
  AgentDeleted |
  SocketConnected |
  SocketDisconnected |
  PluginList;

export type Dispatcher = ThunkDispatch<AppState, void, Actions>;
// We don't care that state can be undefined.
// @ts-expect-error TS(2769) FIXME: No overload matches this call.
const init: () => Store<AppState> = () => createStore<AppState, Actions, unknown, unknown>((state: AppState, action: Actions): AppState => {
  switch (action.type) {
    case ActionTypes.AgentChange: {
      const changed = action.changed;
      return { ...state, agents: state.agents.map(a => a.agent_uid === changed.agent_uid ? changed : a) };
    }
    case ActionTypes.AgentList: {
      return { ...state, agents: action.agents };
    }
    case ActionTypes.AgentAdded: {
      return { ...state, agents: _.uniqBy([...state.agents, action.added], a => a.agent_uid) };
    }
    case ActionTypes.AgentDeleted: {
      return { ...state, agents: state.agents.filter(a => a.agent_uid !== action.deleted.agent_uid) };
    }
    case ActionTypes.SocketConnected: {
      return { ...state, connected: true };
    }
    case ActionTypes.SocketDisconnected: {
      return { ...state, connected: false };
    }
    case ActionTypes.PluginList: {
      return { ...state, plugins: action.plugins };
    }
  }
  return state;
}, INITIAL_STATE);

export default init;
